<template>
    <div class="engine-share-page">
        <div class="share-wapper" v-if="engineInfo && !engineInfo.joined">
            <div class="title">{{engineInfo.owner.phone}}邀请你成为引擎的协作者</div>
            <div class="content">
                <div class="fonts-15 margin-b-10">请输入密码访问引擎</div>
                <el-input v-model="password" type="password" show-password placeholder="请输入密码">
                    <el-button slot="append" @click="submitJoinEvent">确认</el-button>
                </el-input>
                <div class="engine-info">
                    <div class="name">
                        <svg-icon :name="`ie-${engineInfo.icon}`"></svg-icon>
                        <span>{{engineInfo.engineName}}</span>
                    </div>
                    <div class="description">{{engineInfo.descr || '暂无描述'}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { myEngineApi } from "@/utils/api";
export default {
    data() {
        return {
            password: '',
            loading: false,
            engineInfo: null,
            query:{},
        }
    },
    created() {
        this.query = this.$route.params;
        this.getEngineShareInfo();
    },
    methods: {
        getEngineShareInfo(){
            let url = this.$ajax.format(myEngineApi.queryShareInfo,{id: this.query.id}) + `?sharedToken=${this.query.token}`;
            this.$ajax.get(url).then(res => {
                if (res.status === 200){
                    let data = res.data;
                    this.engineInfo = data;
                    if (data.joined){
                        this.$router.replace({path:'/engine/detail', query: {id: data.modelId}})
                    }
                }
            });
        },
        submitJoinEvent(){
            if(!this.password){
                this.$message.error('请输入密码');
                return;
            }
            let url = this.$ajax.format(myEngineApi.submitJoin, {id: this.query.id}) + 
                `?sharedToken=${this.query.token}&pwd=${this.password}`;
            this.loading = true;
            this.$ajax.post(url, {}).then(res => {
                if (res.status === 200){
                    this.$message.success('加入引擎成功');
                    this.$router.replace({path:'/engine/detail', query: {id: this.engineInfo.modelId}})
                }
            }).finally(() =>{
                this.loading = true;
            });
            
        }
    },
    mounted() {
        
    }
}
</script>
<style lang="scss" scoped>
.engine-share-page{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    .share-wapper{
        width: 480px;
        background: #FFFFFF;
        box-sizing: border-box;
        border: 1px solid #DCDFE6;
        border-radius: 10px;
        > .title{
            text-align: center;
            border-bottom: 1px solid #DCDFE6;
            font-size: 14px;
            box-sizing: border-box;
            padding: 15px 0;
        }
        > .content{
            padding: 20px 30px;
            .engine-info{
                background: #FFFFFF;
                border: 1px solid #D3D5D7;
                border-radius: 6px;
                margin-top: 10px;
                padding: 20px;
                .name{
                    font-size: 15px;
                    span {
                        margin-left: 8px;
                        font-weight: 500;
                    }
                }
                .description{
                    font-size: 12px;
                    line-height: 20px;
                    color: #666666;
                    margin-top: 10px;
                }
            }
            ::v-deep .el-input-group__append{
                background-color: #000000;
                color: #fff;
                border-color: #000000;
                padding: 0 35px;
            }
        }
    }
}
</style>
